<template>
  <div class="list_container">
    <div class="header">
      <div class="picker">
        <van-field readonly clickable label="选择就诊人" :value="patName" @click="patNamePickerShow = true"
          right-icon="arrow-down" />
        <van-popup v-model="patNamePickerShow" round position="bottom">
          <van-picker show-toolbar :columns="patNameList" @cancel="patNamePickerShow = false" @confirm="patNameConfirm" />
        </van-popup>
      </div>
      <van-notice-bar wrapable color="#1989fa" background="#ecf9ff"
        left-icon="info-o">温馨提示：默认支持查询开单日期起90天前至今天的检查单</van-notice-bar>
      <div class="picker">
        <van-field readonly clickable label="查询开始日期" :value="date" @click="datePickerShow = true" right-icon="arrow-down"
          class="picker" />
        <van-popup v-model="datePickerShow" round position="bottom">
          <van-datetime-picker v-model="currentDate" type="date" :min-date="minDate" :max-date="maxDate"
            :formatter="formatter" @confirm="dateConfirm" @cancel="dateCancel" />
        </van-popup>
      </div>
    </div>
    <div class="list">
      <div v-for="(item, index) in reportList" :key="index" class="item" @click="navDetail(item.examNo, item.deptName)">
        <van-image class="avatar" width="60" height="60" src="https://xheryuanapp.xh2ph.com:8443/wxxcx/wx/4.png"
          fit="contain" />
        <div class="content">
          <div>
            <span>开单日期：</span>
            <span style="color:#1989fa;font-size: 17px;">{{ item.applyTime }}</span>
          </div>
          <div>
            <span>检查编号：</span>
            <span style="color:#1989fa;font-size: 17px;">{{ item.examNo }}</span>
          </div>
          <div>
            <span>检查科室：</span>
            <span style="color:#1989fa">{{ item.deptName }}</span>
          </div>
          <div>
            <span>检查名称：</span>
            <span style="color:#1989fa">{{ item.examName }}</span>
          </div>
          <van-icon name="arrow" size="20px" />
        </div>
      </div>
    </div>
    <!-- 病理 -->
    <div class="list">
      <div v-for="(item, index) in radiateList" :key="index" class="item"
        @click="navRadiateDetail(item.reportDate, item.applyNo, item.report_doc, item.descript1, item.result1, item.checkarea)">
        <van-image class="avatar" width="60" height="60" src="https://xheryuanapp.xh2ph.com:8443/wxxcx/wx/4.png"
          fit="contain" />
        <div class="content">
          <div>
            <span>检查时间：</span>
            <span style="color:#1989fa;font-size: 17px;">{{ item.checkTime }}</span>
          </div>
          <div>
            <span>检查编号：</span>
            <span style="color:#1989fa;">{{ item.applyNo }}</span>
          </div>
          <div>
            <span>报告医生：</span>
            <span style="color:#1989fa">{{ item.report_doc }}</span>
          </div>
          <div>
            <span>检查区域：</span>
            <span style="color:#1989fa">{{ item.checkarea }}</span>
          </div>
          <van-icon name="arrow" size="20px" />
        </div>
      </div>
    </div>
    <van-empty description="暂无内容" v-if="reportList === ''" />
  </div>
</template>
 
<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "List",
  data () {
    return {
      patName: "",
      patNamePickerShow: false,
      patNameList: [],
      patNameArray: [],
      patNameIndex: 0,
      medicalCardNo: "",
      minDate: new Date(1800, 0, 1),
      maxDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      currentDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      date: "",
      datePickerShow: false,
      end: "",
      reportList: [],
      radiateList: []
    };
  },
  created () {
    this.name = this.$route.query.name;
    this.date = this.formatTime(-90);
    this.end = this.formatTime(0);
    this.getMembers();
    this.patNameIndex = localStorage.getItem("patNameIndex") || 0;
    localStorage.removeItem("patNameIndex");
  },
  
  methods: {
    //获取所有建档人
    async getMembers () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        openId: window.localStorage.getItem("openId"),
      });
      const { data: res } = await formPost(
        "/wxapp/userBindRecord/userBindRecord/getAllUseInfo",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.patNameArray = res.data;
        for (let i = 0; i < this.patNameArray.length; i++) {
          this.patNameList.push(res.data[i].name + '(' + res.data[i].pat_card_no + ')');
        }
        this.patName = this.patNameList[this.patNameIndex];
        if (this.name === "检查报告") {
          this.getCheckResultList();
        } else if (this.name === "检验报告") {
          this.getExamineReportList();
        } else if (this.name === "放射报告") {
          this.getRadiateReportDetail();
        } else if (this.name === "病理报告") {
          this.getPathologyReportDetail();
        }
      } else {
        this.$toast.fail(res.msg);
        setTimeout(() => {
          this.$router.push({
            path: "/file"
          });
        }, 2000);
      }
    },
    // 获取检查报告列表
    async getCheckResultList () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost(
        "/wxapp/outpatient/checkResultList",
        {
          beginDate: this.date,
          endDate: this.end,
          patIdCard: this.patNameArray[this.patNameIndex].card_no,
          patCardNo: this.patNameArray[this.patNameIndex].pat_card_no,
          patCardType: "1"
          // patCardType: "1",
          // patCardNo: "22111872",
          // beginDate: "2022-09-01",
          // endDate: "2022-09-30"
        }
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.reportList = res.data.item;
      } else {
        this.reportList = "";
        this.$toast.fail(res.msg);
      }
    },
    // 获取检验报告列表
    async getExamineReportList () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost(
        "/wxapp/outpatient/examineReportList",
        {
          beginDate: this.date,
          endDate: this.end,
          patIdCard: this.patNameArray[this.patNameIndex].card_no,
          patIdCard: this.patNameArray[this.patNameIndex].card_no,
          patCardNo: this.patNameArray[this.patNameIndex].pat_card_no,
          patCardType: this.patNameArray[this.patNameIndex].pat_card_type
        }
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.reportList = res.data.item;
      } else {
        this.reportList = "";
        this.$toast.fail(res.msg);
      }
    },
    navRadiateDetail (reportDate, applyNo, report_doc, descript1, result1, checkarea) {
      this.$router.push({
        path: "/report/detail",
        query: {
          reportDate,
          applyNo,
          report_doc,
          descript1,
          result1,
          checkarea,
          name: this.name,
        }
      });
    },
    // 放射报告详情
    async getRadiateReportDetail () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost(
        "/wxapp/outpatient/risResultDetail",
        {
          patCardNo: this.patNameArray[this.patNameIndex].pat_card_no,
          beginDate: this.date,
          endDate: this.end
        }
      );
      this.$toast.clear();
      if (res.code === 0) {
        for (let i = 0; i < res.data.examine.length; i++) {
          res.data.examine[i].result1 = res.data.examine[i].result.replace(/\\n/g, "\n")
          res.data.examine[i].descript1 = res.data.examine[i].descript.replace(/\\n/g, "\n")
        }
        this.radiateList = res.data.examine
      } else {
        this.$toast.fail(res.msg);
      }
    },
    // 病理报告详情
    async getPathologyReportDetail () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost(
        "/wxapp/outpatient/pisResultDetail",
        {
          patCardNo: this.patNameArray[this.patNameIndex].pat_card_no,
          beginDate: this.date,
          endDate: this.end
        }
      );
      this.$toast.clear();
      if (res.code === 0) {
        for (let i = 0; i < res.data.examine.length; i++) {
          res.data.examine[i].result1 = res.data.examine[i].result.replace(/\\n/g, "\n")
          res.data.examine[i].descript1 = res.data.examine[i].descript.replace(/\\n/g, "\n")
        }
        this.radiateList = res.data.examine
        console.log(111, res.data.examine);
      } else {
        this.$toast.fail(res.msg);
      }
    },
    patNameConfirm (value) {
      this.patName = value;
      this.patNameIndex = this.patNameList.findIndex(item => {
        return item === value;
      });
      window.localStorage.setItem("patNameIndex",this.patNameIndex)
      this.patNamePickerShow = false;
      if (this.name === "检查报告") {
        this.getCheckResultList();
      } else if (this.name === "检验报告") {
        this.getExamineReportList();
      } else if (this.name === "放射报告") {
        this.getRadiateReportDetail();
      } else if (this.name === "病理报告") {
        this.getPathologyReportDetail();
      }
    },
    formatter (type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return `${val}日`;
    },
    formatTime (num) {
      let now = new Date();
      let nowTime = now.getTime();
      let oneDayTime = 24 * 60 * 60 * 1000;
      let ShowTime = nowTime + num * oneDayTime;
      let currentShowTime = nowTime + -6 * oneDayTime;
      let myDate = new Date(ShowTime);
      let currentDate = new Date(currentShowTime);
      let y = myDate.getFullYear(); //年
      let m =
        myDate.getMonth() + 1 < 10
          ? "0" + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1; //月
      let d = myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate(); //日
      this.currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );
      return y + "-" + m + "-" + d;
    },
    dateFormat (dateData) {
      let date = new Date(dateData);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let time = y + "-" + m + "-" + d;
      let today = new Date().getFullYear();
      return time;
    },
    dateConfirm (value) {
      this.date = this.dateFormat(value);
      this.datePickerShow = false;
      if (this.name === "检查报告") {
        this.getCheckResultList();
      } else if (this.name === "检验报告") {
        this.getExamineReportList();
      } else if (this.name === "放射报告") {
        this.getRadiateReportDetail();
      } else if (this.name === "病理报告") {
        this.getPathologyReportDetail();
      }
    },
    dateCancel () {
      this.datePickerShow = false;
    },
    navDetail (examNo, deptName) {
      this.$router.push({
        path: "/report/detail",
        query: {
          name: this.name,
          examNo: examNo,
          deptName: deptName,
          patCardNo: this.patNameArray[this.patNameIndex].pat_card_no,
          patCardType: this.patNameArray[this.patNameIndex].pat_card_type,
          patIdCard: this.patNameArray[this.patNameIndex].card_no,
        }
      });
    }
  }
};
</script>
 
<style scope>
.list_container .header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
  border-bottom: 2px solid rgb(201, 191, 191);
}

.list_container .header .picker .van-cell {
  padding: 30px;
}

.list_container .header .picker .van-cell::after {
  border: 0;
}

.list_container .header .picker .van-field__label {
  text-align: left;
  font-size: 40px;
}

.list_container .header .picker .van-field__control {
  text-align: right;
  margin-right: -10px;
  font-size: 40px;
}

.list_container .header .picker .van-icon {
  font-size: 40px;
}

.list_container .header .picker .van-picker-column {
  font-size: 40px;
}

.list_container .header .picker .van-picker__toolbar {
  height: 150px;
}

.list_container .header .picker .van-picker__cancel,
.list_container .header .picker .van-picker__confirm {
  font-size: 40px;
  padding: 40px;
}

.list_container .header .van-notice-bar__content {
  font-size: 32px;
}

.list_container .header .van-notice-bar__left-icon {
  font-size: 32px;
}

.list_container .list {
  padding-bottom: 60px;
}

.list_container .list .item {
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  font-size: 36px;
  padding: 20px 30px;
  padding-right: 60px;
}

.list_container .list .avatar {
  flex: 1;
}

.list_container .list .item .content {
  flex: 4;
  margin-left: 20px;
  line-height: 70px;
}

.list_container .list .item .content .van-icon {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}
</style>